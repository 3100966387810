import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Seo from "../components/seo";
import TwoColumnLayout from '../components/twoColumnLayout';
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageGrid1x1 = styled(GatsbyImage)`
 width: 100%;
 grid-column-start: 1;
 grid-column-end: 3;
 ${MEDIA.PHONE`
   grid-column-start: 1;
   grid-column-end: 1;
   `};
`

const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(50% - 2rem, 0fr));
grid-gap: 2rem;
margin: 2rem;
${MEDIA.PHONE`
  grid-template-columns: repeat(auto-fill, minmax(100%, 0fr));
  `};
`

const TextContainer = styled.div`
position: fixed;
top: 0;
bottom: 0;
max-width: 533px;
z-index: 1000;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
padding-right: 2rem;
padding-top: 2rem;
&::-webkit-scrollbar {
    display: none;
}
${MEDIA.TABLET`
    width: 100%;
    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
  `};
${MEDIA.PHONE`
  width: 100%;
  position: static;
  align-items: left;
  padding: 2rem;
  padding-bottom: 0;
  z-index: 10;
  `};
`

const HTMAZ = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "HTMAZ"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  const images = [];
  for (let i = 0; i < data.allFile.edges.length; i++) {
    images[i] = getImage(data.allFile.edges[i].node)
  }
  return(
        <TwoColumnLayout
        Column1Size="66"
        Column1Content={
          <GridContainer>
            {images.map((image, index) => (
                <ImageGrid1x1 image={image} alt={data.allFile.edges[index].node.name} key={index}/>
            ))}
          </GridContainer>

        }
        Column2Size="33"
        Column2Content={
          <TextContainer>
          <Seo title="How-to-Move-a-Zoo" />
          <Header siteTitle="Jake Mu" />
          <h2>
            <i>How to Move a Zoo</i>
            <br /><br />
            {'How to Move the Zoo takes visitors on a century old story as the animals of Moore Park Zoo marched, flew and hopped to their new home at Taronga Zoo. Visitors are invited to create their own animal and follow them across the living mural as they make their way through the streets of Sydney.'}
            <br /><br />
            <b>client:</b><br />
            {'Sydney Living Museums | Museum of Sydney'}
            <br /><br />
            <b>role:</b><br />
            {'Experience Designer @ Grumpy Sailor'}
            <br />
            <i>{'– Design Lead'}</i><br />
            <i>{'– Concepting'}</i><br />
            <i>{'– Art Direction'}</i><br />
            <br />
            <b>collaborators:</b><br />
            {/* {'Lead: Grumpy Sailor'}<br /> */}
            {'Illustration by Ollie Davis'}
            <br /><br />
            <b>photographs:</b><br />
            {'1-3: James Horan'}<br />
            {'4-6: Jake Mu'}
          </h2>
        </TextContainer>

        }
        />
  )
}

export default HTMAZ;
